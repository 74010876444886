import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info'
import axios from 'axios'

import '@/assets/css/app.css'
import '@/assets/css/mbapp.css'
import '@/assets/iconfont/iconfont.css'

import 'animate.css'
import VueClipboard from 'vue-clipboard2';
// 多语言
import VueI18n from 'vue-i18n'
import messages from './common/locale/index.js'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// import Swiper from './static/plugins/swiper-4.3.5/js/swiper.min.js'
// import wow from '@/static/plugins/wow.min.js'

// Vue.use(Swiper)
// Vue.use(wow)


// 图片预览 --- npm install vue-photo-preview --save

import Viewer from 'v-viewer' // 引入js
import 'viewerjs/dist/viewer.css' // 引入css
import VueLazyload from 'vue-lazyload';
let lang = 'ru';
if(window.location.origin == 'https://www.rangbuypro.com'){
  lang = 'en_us'
}
// 多语言
let i18nConfig = {
	locale: localStorage.getItem('think-lang') ? localStorage.getItem('think-lang') : lang,
	messages
};
Vue.use(VueI18n);
const i18n = new VueI18n(i18nConfig);

//用于设置title、描述、关键字，解决SEO的问题
Vue.use(MetaInfo);
Vue.use(ViewUI)
// Vue.use(axios);
Vue.use(VueClipboard);
Vue.use(VueLazyload) ;
Vue.use(Viewer);
// axios.defaults.baseURL = 'http://www.hangda.com/api'
axios.defaults.baseURL = 'http://api.kmaonan.com/api'
Vue.prototype.$axios = axios
Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()

new Vue({
  i18n,
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')

