<template>
  <div class="_pc-pad-top-50 _pc-pad-x-260" style="background-color:#222222;">
   
    <div class="_flex-row-bet _pc-pad-bottom-35">
      <div class="_white _pc-font-16">
        <img class="_pc-w-165" src="@/assets/image/yima/index/logo.png" alt="">
        <div class="_pc-w-45 _pc-pad-top-25 _pc-mar-bottom-25" style="border-bottom:1px solid #999999;"></div>
        <div class="_flex-row-mid _pc-mar-bottom-20"> <img class="_pc-w-20 _pc-mar-right-10" src="@/assets/image/footer-wechat-ico.png" alt=""> <div v-html="wechat.content"></div></div>
        <div class="_flex-row-mid _pc-mar-bottom-30"> <img class="_pc-w-20 _pc-mar-right-10" src="@/assets/image/footer-phone-ico.png" alt=""> <div v-html="phone.content"></div></div>
        <router-link class="" to=""><div @click="showPop" class="_btn-hover _flex-row-mid _pc-pad-x-30 _pc-pad-bottom-10 _pc-pad-top-10" style="border-radius:25px;background-color:#0BB4E7;">反馈与建议 <img class="_pc-w-10 _pc-mar-left-10" src="../assets/image/right-ico.png" alt=""> </div></router-link>
      </div>

      <div class="_flex-row">
        <div class="_flex-col-mid _footer-nav">
            <div class="_pc-font-18 _pc-mar-bottom-5" style="color:#0BB4E7;">一马集运</div>
            <div class="_flex-col-mid _w-max">
              <router-link class="_pc-mar-top-30 _pc-font-16 _w-max _btn-hover" v-show="item.status" v-for="(item,index) in nav_list" :key="index"  :to='item.link' ><div class=" _white">{{item.title}}</div></router-link>
            </div>
        </div>

        <div class="_flex-col-mid _footer-nav">
            <div class="_pc-font-18 _pc-mar-bottom-5 _w-max" style="color:#0BB4E7;">常见问题</div>
            <div class="_flex-col-mid _w-max">
              <router-link :to="{path:'/question/detail',query:{id:item.id,f_id:item.f_id}}" class="_pc-mar-top-30 _pc-font-16 _w-max _btn-hover" style="cursor:pointer;" v-for="(item,index) in quest_list" :key="index" v-show="item.is_hot" ><div class=" _white" @click="to_quest(item.id,item.f_id)">{{item.title}}</div></router-link>
            </div>
        </div>


        <div class="_flex-col-mid _footer-nav">
            <div class="_pc-font-18 _pc-mar-bottom-5" style="color:#0BB4E7;">售后服务</div>
            <div class="_flex-col-mid _w-max">
              <a class="_pc-mar-top-30 _pc-font-16 _w-max _btn-hover" v-for="(item,index) in friend_nav" :key="index"  :href='item.link'><div class=" _white">{{item.title}}</div></a>
            </div>
        </div>
      </div>

      <div class="_flex-row _white _pc-font-18">
        <div class="_flex-col-mid _pc-mar-right-55">
          <img class="_pc-w-160 _pc-mar-bottom-30" :src="routine.image_input" alt=""/>
          官方小程序
        </div>
        
        <div class="_flex-col-mid">
          <img class="_pc-w-160 _pc-mar-bottom-30" :src="account.image_input" alt=""/>
          官方公众号
        </div>
      </div>

    </div>



    <div class="_pc-font-14 _pc-pad-top-15 _pc-pad-bottom-15 _flex-row-center-mid" style="border-top:1px solid rgba(255,255,255,0.2);color:#FFFFFF;">
      <div class="_pc-mar-right-25">深圳万励达科技有限公司</div>
    <div class="_flex-row-mid"> <img class="_pc-w-15 _pc-mar-right-10" src="@/assets/image/footer-ico.png" alt=""> <a style="color:#FFFFFF;" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2022110250号-1</a></div>
    </div>



      <!-- 弹窗 -->
  <div class="head-pop" v-if="is_show" >
    <div class="_pc-pad-x-30 _pc-pad-y-40 _pos-rel _white-bg _pc-w-650 _pc-font-16" style="border-radius:12px">
      <div class="_pos-rel _flex-row-center-mid ">
        <div class="_pc-font-24 _b">反馈与建议</div>
        <img class="_pc-w-30 _pos-abs-right" @click="open_show" src="@/assets/image/guanbi.png" alt="">
      </div>

      <div class="_flex-row-bet-mid _pc-mar-top-20">
        <input type="text" class="_pc-w-230 _pc-mar-right-20 _pc-pad-x-20 _pc-pad-y-15 _grey-f9-bg" placeholder="您的称呼" v-model="data.name">
        <input type="text" class="_flex-1 _pc-pad-x-20 _pc-pad-y-15 _grey-f9-bg" placeholder="您的联系方式" v-model.number="data.phone" maxlength="11">
      </div>

      <textarea class="_pc-pad-x-20 _pc-pad-y-15 _w-max _pc-mar-top-20 _grey-f9-bg" style="border-radius:12px;" cols="30" rows="5" placeholder="请在此留下您的宝贵意见..." v-model="data.content"></textarea>


      <div class="_w-max _flex-row-center-mid _pc-mar-top-15">
        <router-link to="">
          <div class="_btn-hover _pc-pad-y-15 _pc-pad-x-110 _white " style="background:#0BB4E7;" @click="save">
            提交留言
            <img class="_pc-w-20 _pc-mar-left-10" src="@/assets/image/right-ico.png" alt="">
          </div>
        </router-link>
      </div>
    </div>

  </div>

  </div>
</template>

<script>
export default {
  name: 'JiayeFooter',

  data() {
    return {
      is_show:false,
      nav_list:[],
      data:{
        name:'',
        phone:'',
        content:'',
      },
      checked:true,
      quest_list:[],
      routine:{},
      account:{},
      phone:[],
      wechat:[],
      friend_nav:[],
      web_logo:{},//网站logo
    };
  },

  beforeMount() {
    this.get_routine();
  },
  mounted() {
    this.get_nav();
    this.get_news();
    this.get_firend_nav();
    this.get_logo();
  },

  methods: {
    showPop(){
      this.is_show = true;
      let body = document.querySelector('body');
      body.style.height = 100+'vh';
      body.style.overflow = 'hidden';
    },

    open_show(){
      this.is_show = false;
      let body = document.querySelector('body');
      body.style.height = 100+'%';
      body.style.overflow = 'auto';
    },

    

    
    async get_firend_nav(){  //获取友情导航栏
      await this.$axios.get('site/link_list',{}).then(res=>{
        this.friend_nav =res.data.data.list
      })
    },


    async get_nav(){  //获取导航栏
      await this.$axios.get('site/nav_list/0',{}).then(res=>{
        res.data.data.list.forEach(val=>{
          if(val.link != '/question/detail'){
            this.nav_list.push(val)
          }
        })
      })
    },


    async get_logo(){ //获取网站logo
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=25&page=1&limit=15').then(res=>{
        that.web_logo = res.data.data.all[0];
        that.index_logo = res.data.data.all[1];
      })
    },

    to_quest(id,f_id){//前往常见问题
      // this.$router.push({path:'/question/detail',query:{id,f_id}})
    },
    async get_news(){  //获取常见问题列表
      let that = this;
      await that.$axios.get('get_article_tree?id=14',{}).then(res=>{
        let list = [];
        res.data.data[0].child.forEach((val=>{
          val.artcile.forEach(value=>{
            value.f_id = val.id;
            list.push(value)
          })

        }))

        this.quest_list = list;
      })
    },


    async get_routine(){ //获取小程序/公众号
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=7&page=1&limit=15').then(res=>{
        that.routine = res.data.data.all[3];
        that.account = res.data.data.all[2];
        that.phone = res.data.data.all[1];
        that.wechat = res.data.data.all[0];
      })
    },


    async save(){
      let that = this;
      let data = that.data;
      if(data.name){}else{alert('请填写名字'),that.checked = true;return}
      if(data.phone){}else{alert('请填写手机号码'),that.checked = true;return}
      if(data.content){}else{alert('请填写留言内容'),that.checked = true; return}

      await this.$axios.post('site/message_post',data).then(res=>{
        alert(res.data.msg);
        that.checked = true;
      })

      that.open_show();
      that.data = {};
    },
  },
};
</script>

<style scoped>
._footer-nav{margin-right:3.64583vw;}
._footer-nav:last-child{margin-right: 0;}
input{
  border: 0;
}
textarea{
  border: 0;
}

._btn-hover:hover{opacity: 0.4;}
</style>