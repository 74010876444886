<template>
	<div class="footer page-footer-container _pc-pad-top-40 _pc-pad-x-300 _pc-pad-bottom-30 _pc-w-max mb-pad-0 mb-mar-0">
		<div class="_flex-col display-none">
			<img class="_pc-w-150" :src="logo" alt="">
			<div class="line"></div>
			<div class="_pc-mar-bottom-20 _pc-font-14 _white" style="opacity: 0.6;" v-if="lang == 'zh_cn'">Copyright©2023 {{ agent_info.name }}</div>
			<div class="_pc-mar-bottom-20 _pc-font-14 _white" style="opacity: 0.6;" v-else-if="lang == 'en_us'">Copyright©2023 {{ agent_info.name_en }}</div>
			<div class="_pc-mar-bottom-20 _pc-font-14 _white" style="opacity: 0.6;" v-else>Copyright©2023 {{ agent_info.name_ru }}</div>
			<!-- <div class="_flex-row-mid _white">
				<img class="_white-bg" style="width: 15px;height: 15px;" src="~@/assets/image/hangda/beian.png" alt="">
				<div class="_pc-font-14" style="margin-left: 14px;opacity: 0.6;" >{{ config_list.record_No }}</div>
			</div> -->
		</div>
		<div class="_white _flex-col display-none" style="width: calc(100% / 2);padding-left: 80px;">
			<div class="li _flex-row _pc-mar-bottom-40">
				<div class="left _pc-w-50" style="flex-shrink: 0;">
					<img class="_w-max" style="padding-top: 12px;" src="~@/assets/image/hangda/footer_phone.png" alt="">
				</div>
				<div class="right _pc-mar-left-20 _flex-col">
					<span class="_pc-font-18" style="padding-bottom: 17px;">{{ $t('联系电话') }}</span>
					<span class="_pc-font-28 _b" style="color: #4284DC;">{{ agent_info.link_phone }}</span>
				</div>
			</div>
			<div class="li _flex-row">
				<div class="left _pc-w-50" style="flex-shrink: 0;">
					<img class="_w-max" style="padding-top: 12px;" src="~@/assets/image/hangda/footer_dizhi.png" alt="">
				</div>
				<div class="right _pc-mar-left-20 _flex-col">
					<span class="_pc-font-18" style="padding-bottom: 17px;">{{ $t('地址') }}</span>
					<span class="_pc-font-20 _b" style="color: #4284DC;line-height: 1.45;">{{ agent_info.link_address }}</span>
				</div>
			</div>
		</div>
		<div></div>
		<div class="_pc-display-none zhanwei">
			<div class="ipc">
				<div style="flex:1;display: flex;justify-content: center;">123</div>
				<div style="flex:1;display: flex;justify-content: center;">456</div>
			</div>
			<div class="nav">
				<div class="item">
					<div></div>
					<div>返回首页</div>
				</div>
				<div class="item">
					<div></div>
					<div>登录/注册</div>
				</div>
			</div>
		</div>
		<div class="_pc-display-none mb-footer-container">
			<div class="ipc">
				<div style="flex:1;display: flex;justify-content: center;"></div>
				<div style="flex:1;display: flex;justify-content: center;" v-if="lang == 'zh_cn'">Copyright©2023 {{ agent_info.name }}</div>
				<div style="flex:1;display: flex;justify-content: center;" v-else-if="lang == 'en_us'">Copyright©2023 {{ agent_info.name_en }}</div>
				<div style="flex:1;display: flex;justify-content: center;" v-else>Copyright©2023 {{ agent_info.name_ru }}</div>
			</div>
			<div class="nav">
				<div class="item" @click="link('/')">
					<div></div>
					<div>{{ $t('网站首页') }}</div>
				</div>
				<div class="item" v-if="!Islogin" @click="link('/login')">
					<div></div>
					<div>{{ $t('登录') }}/{{ $t('注册') }}</div>
				</div>
				<div class="item" v-else @click="link('/user_mobile')">
					<div></div>
					<div>{{ $t('page_title.user') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getConfig, bannerList } from '@/api/jiyun';
	import { getCookies, removeCookies, checkLogin } from "@/libs/util.js"
	export default {
		name: 'pcWebHeadTwo',
		data() {
			return {
				config_list: '',
				logo: '',
				agent_info: {},
				lang: '',
				Islogin:false,
			}
		},
		mounted(){
			
		},
		created(){
			this.lang = localStorage.getItem('think-lang') || 'ru';
			checkLogin();
			let token = getCookies('web-token');
			if(token){
				this.Islogin = true;
				console.log(this.Islogin)
			}else{
				this.Islogin = false;
				console.log(this.Islogin)
			}
			this.getConfig();
			this.bannerList();
		},
		methods: {
			getConfig(){
				getConfig().then((res)=>{
					this.config_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
					this.logo = res.data.logo;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			link(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style>
	.page-footer-container {
		display: block;
	}
</style>
<style scoped>
	
	@media screen and (min-width: 768px) {
		.footer {
			background-image: url(../assets/image/hangda/footer_bg.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.line {
			margin-top: 16px;
			width: 21px;
			height: 3px;
			background: #4284DC;
			margin-bottom: 46px;
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px) {
		.grid-column-max-span{
			grid-column: 1 / -1;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-0 {
			padding: 0;
		}
		.mb-mar-0 {
			margin: 0;
		}
		.zhanwei {
			width: 100%;
			opacity: 0;
			pointer-events: none;
		}
		.zhanwei .ipc {
			background: #222222;
			color: rgba(255, 255, 255, 0.6);
			font-size: 0.9375rem;
			padding: 0.9375rem 1.875rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.zhanwei .nav {
			width: 100%;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.zhanwei .nav .item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;
			height: 100%;
			cursor: pointer;
			position: relative;
			padding: 1.5rem 0rem;
		}
		.zhanwei .nav .item::after {
			content: '';
			position: absolute;
			width: 1px;
			height: 40%;
			left: 0;
			background-color: #ccc;
		}
		.mb-footer-container {
			width: 100%;
			position: fixed;
			bottom: 0;
			left: 0;
			z-index: 999;
		}
		.mb-footer-container .ipc {
			background: #222222;
			color: rgba(255, 255, 255, 0.6);
			font-size: 0.9375rem;
			padding: 0.9375rem 1.875rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.mb-footer-container .nav {
			width: 100%;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.mb-footer-container .nav .item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;
			height: 100%;
			cursor: pointer;
			position: relative;
			padding: 1.5rem 0rem;
		}
		.mb-footer-container .nav .item::after {
			content: '';
			position: absolute;
			width: 1px;
			height: 40%;
			left: 0;
			background-color: #ccc;
		}
	}
</style>