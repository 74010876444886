import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../pages/index.vue'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('@/pages/index/index.vue'),
	},
	//   {
	//     path: '/',
	//     name: 'index',
	//     component: index
	//   },
	//   {
	//     path: '/about',
	//     name: 'about',
	//     component: () => import('@/pages/about/index.vue'),
	// },
	// {
	//   path: '/avaluation',
	//   name: 'avaluation',
	//   component: () => import('@/pages/avaluation/index.vue'),
	//   children:[
	//     {
	//       path:'newsList',
	//       name:'avaluation-newList',
	//       component:() => import('@/pages/avaluation/components/newsList')
	//     }
	//   ]
	// },
	// {
	//   path:'/estimated_price',
	//   name:'estimatedPrice',
	//   component:() => import('@/pages/estimated_price/index.vue')
	// },
	// {
	//   path: '/newList',
	//   name: 'newList',
	//   component: () => import('@/pages/newList/index.vue'),
	// },
	// {
	//   path: '/newList/detaile',
	//   name: 'detaile',
	//   component: () => import('@/pages/newList/detaile.vue'),
	// },
	// {
	//   path:'/question',
	//   name:'question',
	//   component:() => import('@/pages/question/question.vue'),
	//   children: [

	//     {
	//         path: 'detail',
	//         name: 'detail',
	//         component: () => import('@/pages/question/detail.vue'),
	//     },
	// ]
	// }
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录'
		},
		component: () => import('@/pages/login/index.vue'),
	},
	{
		path: '/find_pwd',
		name: 'find_pwd',
		meta: {
			title: '找回密码'
		},
		component: () => import('@/pages/find_pwd/index.vue'),
	},
	{
		path: '/register',
		name: 'register',
		meta: {
			title: '注册'
		},
		component: () => import('@/pages/register/index.vue'),
	},
	{
		path: '/addressInfo',
		name: 'addressInfo',
		meta: {
			title: '收货地址'
		},
		component: () => import('@/pages/register/addressInfo.vue'),
	},
	{
		path: '/search',
		name: 'index-search',
		meta: {
			title: '物流查询'
		},
		component: () => import('@/pages/index/search.vue'),
	},
	{
		path: '/question',
		name: 'question',
		meta: {
			title: '常见问题'
		},
		component: () => import('@/pages/index/question.vue'),
	},
	{
		path: '/question_detail',
		name: 'question_detail',
		meta: {
			title: '详情'
		},
		component: () => import('@/pages/index/question_detail.vue'),
	},
	{
		path: '/about_us',
		name: 'aboutUs',
		meta: {
			title: '关于我们'
		},
		component: () => import('@/pages/index/about_us.vue')
	},
	{
		path: '/mb_about_us',
		name: 'aboutUs',
		meta: {
			title: '关于我们'
		},
		component: () => import('@/pages/index/mb_about_us.vue')
	},
	{
		path: '/report',
		name: 'report',
		meta: {
			title: '包裹报备'
		},
		redirect: '/report/reportOnePack',
		component: () => import('@/pages/index/report.vue'),
		children: [
			{
				path: 'reportOnePack',
				name: 'reportOnePack',
				meta: {
					title:'单个报备'
				},
				component: () => import('@/pages/index/components/reportOnePack.vue')
			},
			{
				path: 'reportBatchPack',
				name: 'reportBatchPack',
				meta: {
					title:'批量报备'
				},
				component: () => import('@/pages/index/components/reportBatchPack.vue')
			},
			{
				path: 'reportList',
				name: 'reportList',
				meta: {
					title:'报备记录'
				},
				component: () => import('@/pages/index/components/reportList.vue')
			}
		]
	},
	{
		path: '/estimate',
		name: 'estimate',
		mate: {
			title: '运费预估结果',
		},
		component: () => import('@/pages/index/estimate')
	},
	{
		path: '/order_detail',
		name: 'order_detail',
		meta: {
			title: '订单详情',
		},
		component: () => import('@/pages/user/order_detail')
	},
	{
		path: '/user',
		name: 'user',
		mate: {
			title: '个人中心',
		},
		component: () => import('@/pages/user/index')
	},
	{
		path: '/user_mobile',
		name: 'user_mobile',
		mate: {
			title: '个人中心',
		},
		component: () => import('@/pages/user/mobile_index')
	},
	{
		path: '/changePwd',
		name: 'changePwd',
		mate: {
			title: '修改密码',
		},
		component: () => import('@/pages/user/mobile_changePwd')
	},
	{
		path: '/addressList',
		name: 'addressList',
		mate: {
			title: '地址编辑',
		},
		component: () => import('@/pages/user/mobile_addressList')
	},
	{
		path: '/ChangeAddress',
		name: 'ChangeAddress',
		mate: {
			title: '地址编辑',
		},
		component: () => import('@/pages/user/mobile_changeAddress')
	},
	{
		path: '/orderList',
		name: 'orderList',
		mate: {
			title: '我的订单',
		},
		component: () => import('@/pages/user/mobile_orderList')
	},
	{
		path: '/mobile_orderDetail',
		name: 'mobile_orderDetail',
		mate: {
			title: '订单详情',
		},
		component: () => import('@/pages/user/mobile_orderDetail')
	},
	{
		path: '/message',
		name: 'message',
		mate: {
			title: '消息列表',
		},
		component: () => import('@/pages/user/message')
	},
	{
		path: '/mb_message',
		name: 'message',
		mate: {
			title: '消息列表',
		},
		component: () => import('@/pages/user/mb_message')
	},
	{
		path: '/agreement',
		name: 'agreement',
		mate: {
			title: '协议内容',
		},
		component: () => import('@/pages/agreement/index')
	},
	{
		path: '/list',
		name: 'list',
		mate: {
			title: '申请打包列表',
		},
		component: () => import('@/pages/apply_pack/list')
	},
	{
		path: '/detail',
		name: 'detail',
		mate: {
			title: '确认申请打包',
		},
		component: () => import('@/pages/apply_pack/detail')
	},
	{
		path: '/mb_list',
		name: 'list',
		mate: {
			title: '申请打包列表',
		},
		component: () => import('@/pages/apply_pack/mb_list')
	},
	{
		path: '/mb_detail',
		name: 'detail',
		mate: {
			title: '确认申请打包'
		},
		component: () => import('@/pages/apply_pack/mb_detail')
	},
	{
		path: '/mobile_addressList',
		name: 'mobile_addressList',
		mate: {
			title: '地址管理'
		},
		component: () => import('@/pages/apply_pack/mobile_addressList')
	},
	{
		path: '/mobile_report',
		name: 'mobile_report',
		mate: {
			title: '我的包裹'
		},
		component: () => import('@/pages/user/mobile_report')
	},
	{
		path: '/my_report',
		name: 'my_report',
		mate: {
			title: '我的包裹'
		},
		component: () => import('@/pages/user/my_report')
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,

	routes,
	//每个页面跳转后，都跳转到页面的顶部
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	}
})

export default router