<template>
	<div class="index page-header-container  _white-bg">
		<div class="header _pc-h-80 _white-bg _w-max _flex-row-bet-mid _pc-pad-x-60 _border-bottom  _mb-h-105 _mb-pad-x-30">
			<div class="_pc-w-140 _mb-w-165">
				<img class="logo _pc-h-80" style="width: 100%;height:unset;" :src="logo" alt="">
			</div>
			<!-- head-menu -->
			<div class="header-right-nav">
				<!-- 系统消息 -->
				<router-link class="message-box display-none" :to="'/message'" style="cursor:pointer;">
					<img class="_pc-mar-right-10 _pc-w-15 _mb-w-30 _mb-mar-right-15" src="~@/assets/image/hangda/msg-icon.png" alt="" />
					<div class="_mb-display-none _pc-font-16 _mb-font-28" style="color: #333333;">{{ $t('系统消息') }}</div>
					<div class="_display-none _mb-display-block _mb-font-28" style="color: #333333;">{{ $t('消息') }}</div>
					<div class="message-badeg _mb-display-none" v-if="msgNum > 0">{{ msgNum||'' }}</div>
				</router-link>
				<router-link class="message-box pc-display-none" :to="'/mb_message'" style="cursor:pointer;">
					<img class="_pc-mar-right-10 _pc-w-15 _mb-w-30 _mb-mar-right-15" src="~@/assets/image/hangda/msg-icon.png" alt="" />
					<div class="_mb-display-none _pc-font-16 _mb-font-28" style="color: #333333;">{{ $t('系统消息') }}</div>
					<div class="_display-none _mb-display-block _mb-font-28" style="color: #333333;">{{ $t('消息') }}</div>
					<div class="message-badeg _mb-display-none" v-if="msgNum > 0">{{ msgNum||'' }}</div>
				</router-link>
				<!-- 语言切换 -->
				<div class="lang" style="cursor:pointer;" @click="switchLang">
					<img class="_pc-mar-right-10 _pc-w-15 _mb-w-30 _mb-mar-right-15" src="~@/assets/image/hangda/lang.png" alt="" />
					<div class="_pc-font-16 _mb-font-28" style="color: #333333;">{{ $t('语言切换') }}</div>
					<div class="iconfont2 icon-jiantou _mb-font-32" :style="is_lang ? 'transform: rotate(-90deg);' : ''"></div>
					<div class="select-lang-box" :style="is_lang ? 'display: block;' : ''">
						<div class="li-item"  @click="selectLang('ru')">
							<input class="_pc-mar-right-10" style="width: 17px;height: 17px;flex-shrink: 0;" type="checkbox" name="" id="lang-ru" :value="'ru'" :checked="lang=='ru'"/>
							<label for="lang-ru" style="flex-shrink: 0;" class=" _pc-font-16 _mb-font-28">{{ $t('ru_lang') }}</label>
						</div>
						<div class="li-item" @click="selectLang('zh_cn')">
							<input class="_pc-mar-right-10" style="width: 17px;height: 17px;flex-shrink: 0;" type="checkbox" name="" id="lang-zh" :value="'zh_cn'" :checked="lang=='zh_cn'"  />
							<label for="lang-zh" style="flex-shrink: 0;" class=" _pc-font-16 _mb-font-28">{{ $t('zh_lang') }}</label>
						</div>
						<div class="li-item" @click="selectLang('en_us')">
							<input class="_pc-mar-right-10" style="width: 17px;height: 17px;flex-shrink: 0;" type="checkbox" name="" id="lang-en" :value="'en_us'" :checked="lang=='en_us'"  />
							<label for="lang-zh" style="flex-shrink: 0;" class=" _pc-font-16 _mb-font-28">{{ $t('en_lang') }}</label>
						</div>
					</div>
				</div>
				<!-- 首页 -->
				<router-link class="home _mb-display-none" :to="'/'" style="cursor:pointer;">
					<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/home.png" alt="" />
					<div class="_pc-font-16" style="color: #333333;">{{ $t('网站首页') }}</div>
				</router-link>
				<!-- 已登录 -->
				<div class="user-avatar-box _mb-display-none" v-if="Islogin" style="cursor:pointer;">
					<div class="avatar-box">
						<img :src="logo" alt="" v-if="Islogin"/>
						<img src="~@/assets/image/hangda/defaultAvatar.png" alt="" v-else />
					</div>
					<div class="_pc-font-16 _pc-mar-right-10">{{ userInfo.nickname||'' }}</div>
					<div class="iconfont2 icon-jiantou"></div>
					<div class="user-avatar-menu" style="text-align: center;">
						<div class="li" @click="toUser">
							<router-link class="_w-max _h-max" :to="'/user'">{{ $t('page_title.user') }}</router-link>
						</div>
						<div class="li" style="cursor: pointer;" @click="loginOut()">{{ $t('退出登录') }}</div>
					</div>
				</div>
				<!-- 未登录 -->
				<div class="sign-box mb-display-none" v-else>
					<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/my.png" alt="" />
					<router-link class="_pc-font-16 sign-in" :to="'/login'" style="color: #333333;text-align: center;">{{ $t('登录') }}</router-link>/
					<router-link class="_pc-font-16 sign-up" :to="'/register'" style="color: #333333;text-align: center;">{{ $t('注册') }}</router-link>
				</div>
			</div>
		</div>
		<div class="_pc-h-80 _w-max _mb-h-105"></div>
		<!-- 公告 -->
		<div class="_w-max" style="overflow-x: hidden;overflow-y: hidden;background-color: antiquewhite;" v-show="showSwipers">
			<div class="swipers">
				<div class="swiper-wrapper" style="display: flex;width: 100%;">
					<div class="swiper-slide" v-for="(item,index) in agent_info.notice" :key="index" :data-item="item" style="flex-shrink: 0;cursor: pointer;" @click-slide="lookNotice(item)">
						<span v-if="lang=='zh_cn'" style="line-height: 50px;margin-left: 30px;color: #ff9900;"><Icon type="md-volume-mute" style="margin-right: 5px;font-size: 16px;"/>{{ item.title }}</span>
						<span v-else-if="lang=='en_us'" style="line-height: 50px;margin-left: 30px;color: #ff9900;"><Icon type="md-volume-mute" style="margin-right: 5px;font-size: 16px;"/>{{ item.title_en }}</span>
						<span v-else style="line-height: 50px;margin-left: 30px;color: #ff9900;"><Icon type="md-volume-mute" style="margin-right: 5px;font-size: 16px;"/>{{ item.title_ru }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 轮播图 -->
		<div class="_w-max" style="overflow-x: hidden;overflow-y: hidden;" v-show="showSwiper">
			<div class="swiper">
				<div class="swiper-wrapper" style="display: flex;width: 100%;">
					<div class="swiper-slide _mb-h-260" v-for="(item,index) in agent_info.home_banner" :key="index" style="flex-shrink: 0;">
						<img class="_h-max _w-max" :src="item.url" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from '@/assets/js/swiper.min.js'
	import { getCookies, removeCookies, checkLogin } from "@/libs/util.js"
	import { getUserInfo, submitLogout, noRead, bannerList } from '@/api/jiyun.js'
	export default {
		name: "pcWebHeadTwo",
		data() {
			return {
				lang: this.$i18n.locale,
				link: '',
				showSwiper: true,
				userInfo: {},
				Islogin: false,
				msgNum: 0,
				logo: '',
				is_lang: false,
				agent_info: {},
				showSwipers: true,
			}
		},
		watch: {
			$route: {
				handler(newval, oldval) {
					let that = this;
					that.link = newval.path;
					that.showSwiper = true;
					let hiddenSwiper = [
						'/login',
						'/find_pwd',
						'/register',
						'/user',
						'/order_detail',
						'/message',
						'/addressInfo',
						'/search',
						'/estimate',
						'/report/reportOnePack',
						'/question',
						'/question_detail',
						'/about_us',
						'/agreement',
						'/user_mobile',
						'/changePwd',
						'/ChangeAddress',
						'/addressList',
						'/orderList',
						'/mobile_orderDetail',
						'/mb_list',
						'/mb_detail',
						'/mb_message',
						'/mobile_addressList',
						'/mb_about_us',
						'/mobile_report',
						'/my_report'
					];
					hiddenSwiper.forEach((item, index) => {
						if (that.link == item) {
							that.showSwiper = false;
						}
					});
					that.showSwipers = true;
					let twoHiddenSwiper = [
						'/login',
						'/find_pwd',
						'/register',
						'/user',
						'/order_detail',
						'/message',
						'/addressInfo',
						'/search',
						'/estimate',
						'/report/reportOnePack',
						'/question',
						'/question_detail',
						'/about_us',
						'/agreement',
						'/user_mobile',
						'/changePwd',
						'/ChangeAddress',
						'/addressList',
						'/orderList',
						'/mobile_orderDetail',
						'/mb_list',
						'/mb_detail',
						'/mb_message',
						'/mobile_addressList',
						'/mb_about_us',
						'/mobile_report',
						'/my_report'
					];
					twoHiddenSwiper.forEach((item,index) => {
						if(that.link == item){
							that.showSwipers = false;
						}
					})
					if (!that.lang) {
						localStorage.setItem('think-lang', 'zh_cn');
					}
					let token = getCookies('web-token');
					if(token){
						this.noRead();
					}
					
				}
			},
		},
		created() {
			checkLogin();
			this.$bus.$on('noRead',()=>{
				this.noRead();
			})
			this.bannerList();
			let token = getCookies('web-token');
			if(token){
				this.getUserInfo();
				this.noRead();
			}else{
				this.Islogin = false;
			}
			
		},
		updated(){
			this.nav_swiper();
			this.two_swiper();
		},
		methods: {
			// 查看公告
			lookNotice(index){
				let row = this.agent_info.notice[index];
				let content = '';
				let title = '';
				if(this.lang == 'zh_cn'){
					content = row.content;
					title = row.title;
				}else if(this.lang == 'en_us'){
					content = row.content_en;
					title = row.title_en;
				}else{
					content = row.content_ru;
					title = row.title_ru;
				}
				this.$Modal.info({
					title: title,
					content: content,
				});
			},
			// 选择语言
			switchLang(){
				if(this.is_lang){
					this.is_lang = false;
				}else{
					this.is_lang = true;
				}
			},
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
					this.logo = res.data.logo;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 消息未读数
			noRead(){
				noRead().then((res)=>{
					this.msgNum = res.data.count;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 获取用户信息
			getUserInfo(){
				getUserInfo().then((res)=>{
					this.userInfo = res.data;
					this.Islogin = true;
				}).catch((err)=>{
					this.Islogin = false;
					this.$Message.error(err.msg)
				})
			},
			// 切换语言
			selectLang(lang) {
				let that = this;
				that.lang = lang;
				localStorage.setItem('think-lang', lang);
				window.location.reload()
			},
			nav_swiper(){
				var mySwiper = new Swiper('.swiper',{
					direction: 'horizontal',
					loop:true,
					autoplay : {
						delay:3500,
						disableOnInteraction: false,
					},
					// 如果需要前进后退按钮
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}
				})
			},
			two_swiper(){
				let that = this;
				this.$nextTick(()=>{
					var newSwiper = new Swiper('.swipers',{
						direction: 'horizontal',
						loop:true,
						observer: true,
						observeParents: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						},
						autoplay : {
							delay:3000,
							disableOnInteraction: false,
						},
						// 如果需要前进后退按钮
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
						on: {
							click: function(e){
								that.lookNotice(this.realIndex);
							},
						}
					})
				})
			},
			// 退出登录
			loginOut() {
				this.$Modal.confirm({
					title: this.$t('退出登录确认'),
					content: this.$t('您确定退出当前账户吗？'),
					okText: this.$t('confirm'),
					cancelText: this.$t('cancel'),
					onOk(){
						submitLogout().then((res)=>{
							this.$Message.success(res.msg);
							removeCookies('uid');
							removeCookies('web-token');
							removeCookies('web-expires_time');
							this.userInfo = {};
							this.Islogin = false;
							location.reload();
							this.$router.replace({
								path: '/',
							})
						}).catch((err)=>{
							this.$Message.error(err.msg)
						})
					}
				})
			},
			toUser(){
				this.$router.push({
					path: '/user'
				},()=>{})
			},
		}
	}
</script>

<style>
	.page-header-container {
		display: block;
	}
</style>
<style scoped>
	a {
		text-decoration: unset;
		color: unset;
	}

	a:hover {
		color: unset;
	}

	a:visited {
		color: unset;
	}

	a:active {
		color: unset;
	}

	.index {
		width: 100%;
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.header-right-nav {
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		/* cursor: default; */
	}

	.header-right-nav .message-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;
		padding-left: 20px;
		padding-right: 20px;
		position: relative;
	}

	.header-right-nav .message-box .message-badeg {
		padding: 2px 6px;
		border-radius: 100px;
		background-color: #f00;
		color: #ffffff;
		font-size: 12px;
		position: relative;
		top: -10px;
		right: 6px;
	}

	.header-right-nav .message-box:hover {
		background-color: rgba(148, 148, 148, 0.1);
		/* cursor: default; */
	}

	.header-right-nav .lang {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;
		padding-left: 20px;
		padding-right: 20px;
		position: relative;
	}

	.header-right-nav .lang:hover {
		background-color: rgba(148, 148, 148, 0.1);
	}

	/* .header-right-nav .lang:hover .icon-jiantou {
		transform: rotate(-90deg);
	} */

	.header-right-nav .lang .icon-jiantou {
		transform: rotate(90deg);
		margin-left: 10px;
		transition: transform 0.6s;
		-webkit-transition: transform 0.6s;
	}

	.header-right-nav .lang .select-lang-box {
		position: absolute;
		color: #999999;
		font-size: 20px;
		background-color: #ffffff;
		top: 100%;
		left: 0;
		padding: 10px 15px;
		display: none;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .15);
		border-radius: 0px 0px 4px 4px;
	}

	/* .header-right-nav .lang:hover .select-lang-box {
		display: block;
	} */

	.header-right-nav .lang .select-lang-box .li-item {
		border-bottom: 1px solid #e4e4e4;
		padding: 10px 0px;
		cursor: pointer;
	}

	.header-right-nav .lang .select-lang-box .li-item:last-child {
		border-bottom: none;
	}

	.header-right-nav .home {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	.header-right-nav .home:hover {
		background-color: rgba(148, 148, 148, 0.1);
		/* cursor: default; */
	}

	.header-right-nav .user-avatar-box {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 20px;
		padding-right: 20PX;
		position: relative;
	}

	.header-right-nav .user-avatar-box:hover {
		background-color: rgba(148, 148, 148, 0.1);
		/* cursor: default; */
	}

	.header-right-nav .user-avatar-box .avatar-box {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #f5f5f5;
		margin-right: 13px;
	}

	.header-right-nav .user-avatar-box .avatar-box img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.header-right-nav .user-avatar-box .icon-jiantou {
		transform: rotate(90deg);
		transition: transform 0.6s;
		-webkit-transition: transform 0.6s;
	}

	.header-right-nav .user-avatar-box:hover .icon-jiantou {
		transform: rotate(-90deg);
	}

	.header-right-nav .user-avatar-box:hover .user-avatar-menu {
		display: block;
	}

	.header-right-nav .user-avatar-box .user-avatar-menu {
		position: absolute;
		color: #999;
		font-size: 16px;
		background-color: #ffffff;
		top: 100%;
		right: 0;
		display: none;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .15);
		border-radius: 0px 0px 4px 4px;
	}

	.header-right-nav .user-avatar-box .user-avatar-menu .li {
		padding: 12px 28px;
		border-bottom: 1px solid #ECECEC;
	}

	.header-right-nav .user-avatar-box .user-avatar-menu .li:hover {
		background: rgba(148, 148, 148, 0.1);
		color: rgba(51, 51, 51, 0.9);
	}

	.header-right-nav .user-avatar-box .user-avatar-menu .li:last-child {
		border-bottom: none;
	}

	.header-right-nav .sign-box {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	.header-right-nav .sign-box:hover {
		background-color: rgba(148, 148, 148, 0.1);
		/* cursor: default; */
	}

	.sign-in,
	.sign-up {
		transition: font-size 0.3s;
		/* cursor: default; */
	}

	.sign-in:hover {
		font-size: 24px;
	}

	.sign-up:hover {
		font-size: 24px;
	}

	@media screen and (max-device-width: 768px) {
		.mb-display-none {
			display: none !important;
		}
		.header-right-nav .home{
			display: none;
		}

		.header-right-nav .user-avatar-box{
			display: none;
		}

		.header-right-nav .message-box {
			padding-left: 4vw;
			padding-right: 4vw;
		}

	}
	@media screen and (min-width: 768px){
		.pc-display-none {
			display: none !important;
		}
	}
	@media screen and (max-width: 768px) {
		.header-right-nav .message-box {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 100%;
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
	}
</style>