<template>
  <!-- 导航栏 -->
  <div>
    <div class="_com-head-box _com-head-height " :style="scroll_top<200 && nav == '/'?'color:white;':'background-color:#FFF;color:black;'">

      <div class="_pc-pad-x-80 _flex-row-bet-mid _h-max" style="border-bottom:1px solid rgba(255,255,255,0.4);">
        <!-- logo -->
        <div class="_pc-w-145">
          <img :class="scroll_top<200 && nav == '/' ? '_display-none':''" style="width:100%;" :src="web_logo.image_input"   alt="">
          <img :class="scroll_top<200 && nav == '/' ? '':'_display-none'" style="width:100%;" :src="index_logo.image_input" alt="">
        </div>
  <!--  -->

        <div class="_pc-font-16 _flex-row-mid _h-max" style="width:60%;">

          <div class="_h-max _flex-1 _flex-row-ard-mid _pc-pad-right-60 " >
            <!-- 栏目 -->
            <router-link class="_pos-rel _h-max _flex-row-mid"  :to="item.data? item.link+'?data='+item.data :item.link"  v-for="(item,index) in nav_list" :key="index"  v-show="item.status">
              <div class="_b" :class="nav == item.link? scroll_top<200 && nav == '/'?'_white':'nav_color' :'nav-opac'" >{{item.title}}</div>
              <div class="_pos-abs-bottom  _w-max" :class="scroll_top<200 && nav == '/'? 'nav-index-border-top' :'nav-border-top '" v-if="nav == item.link"></div>
            </router-link>
          </div>

          <div class="nav-opac" style="border-right:1px solid white;height:35%;"></div>

          <div class="_flex-row-mid _pc-mar-left-60">
            <!-- 微信 -->
            <div class="_pc-mar-right-20 _pos-rel _wechat-box" ><img class="_pc-w-40" src="@/assets/image/yima/index/wechat-ico.png" alt=""> <div class="_pc-w-210 _pos-abs" style="left:-350%;bottom:-500%;"><img class="_w-max" :src="account.image_input" alt=""> </div> </div>

            <div class="_flex-row-mid _pc-pad-x-15 _pc-pad-top-10 _pc-pad-bottom-10 _btn-hover _white-bg" style="cursor:pointer;border-radius:20px;color:#0BB4E7;" @click="show_pop" :class="this.$root._route.path=='/'?'':'_F6-bg'">
              <img class="_pc-w-15 _pc-mar-right-10" src="@/assets/image/yima/index/uni-ico.png" alt="">我要寄货
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
  <div class="head-pop" v-if="is_show" >
    <div class="_pc-w-300 _pos-rel _flex-row-center-mid">
      <img class="_w-max" src="@/assets/image/yima/scan/pop-bg.png" alt="" >
      <img class="_pc-w-210 _pos-abs-bottom _pc-mar-bottom-30 " :src="routine.image_input" alt="">
      <img class="_pos-abs _pc-w-40 _btn-hover" style="bottom:-20%;cursor:pointer;" @click="open_show" src="@/assets/image/pop-push.png" alt="">
    </div>
  </div>


    <!-- 占位符 -->
    <div class="_com-head-height" style=" width:100%;" v-if="nav != '/'"></div>

    <!-- head图片 -->
    <div class="_pos-rel _flex-row-center-mid" v-if="nav == '/'">
      <div class="swiper-container" style="margin:0;width:100%;">
        <div class="swiper-wrapper">

          <div class="swiper-slide" v-for="(item,index) in index_nav" :key="index">
              <img style="width:100%;" :src="item.image" alt="">
              <router-link :to="item.link" class="_w-max _h-max _pos-abs" style="z-index:8;left:0;" v-if="item.is_new ==0"></router-link>
              <a :href="item.link" class="_w-max _h-max _pos-abs" style="z-index:8;left:0;" v-else></a>
          </div>
        
        </div>
        <div v-if="index_nav.length>1" class="swiper-button-prev _pc-w-40 _pc-h-55 _pc-mar-x-80 _swip-pre" style="left:0% ;"></div>
        <div v-if="index_nav.length>1" class="swiper-button-next _pc-w-40 _pc-h-55 _pc-mar-x-80 _swip-next" style="right:0% ;"></div>
      </div>

      <a href="#server" class="_pos-abs _z-index-1" style="bottom:10%;"><img class="_pc-w-70"  src="@/assets/image/head-down.png" alt=""></a>

    </div>

    <!-- <div v-if="nav != '/' && isQuestion && nav == item.link">
      <div   v-for="(item , index) in nav_list" :key='index' >
        <img style="width:100%;" :src="item.image" alt="">
      </div>
    </div> -->
    <div   v-for="(item , index) in nav_list" :key='index' v-show="nav != '/' && isQuestion && nav == item.link">
      <img style="width:100%;" :src="item.image" alt="">
    </div>
   

    <!-- <div   v-for="(item , index) in nav_list" :key='index' v-if=" isQuestion && nav == item.link">
      <img style="width:100%;" :src="item.image" alt="">
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'PcWebHead',

  data() {
    return {
      nav:this.$root._route.path,
      is_show:false,
      nav_list:[
        // {to:'/',title:'主页'},
        // {to:'/newList',title:'最新资讯'},
        // {to:'/user/info',title:'用户信息',data:JSON.stringify({id:18})},
        // {to:'/user/goods',title:'商品信息',data:JSON.stringify({lizi:'李子'})},传参格式
        // {to:'/about',title:'关于我们'},
        // {to:'/question/detail',title:'常见问题'}
      ],
      scroll_top:null,
      nav_img:null,
      isQuestion:true,
      routine:{},
      account:{},
      web_logo:{},//网站logo
      index_logo:{},//主页logo
      index_nav:[],//主页轮播图
    };
  },


  beforeMount() {
    this.get_nav();
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll);
    this.watch_head();
    this.get_routine();
    this.get_logo();
    // new WOW().init();
  },

  watch:{
    $route:{
      handler(newval,oldval){
        if(newval == oldval){ return}
        //fullPath,当前的路由路径
        this.nav = newval.path; 
        if(this.nav == '/'){
          this.nav_swiper();
        }
        let nav = /question/;
        if(nav.test(this.$root._route.path)){
          this.isQuestion = false;
        }else{this.isQuestion = true;}
       
      }
     
    },

    nav_img:{
      handler(newval,oldval){
        this.nav_swiper();
      }
    },
  },

  methods: {

    watch_head(){
      let nav = /question/;
      if(nav.test(this.$root._route.path)){
        this.isQuestion = false;
      }else{this.isQuestion = true;}
    },

    handleScroll(){
      this.scroll_top = document.documentElement.scrollTop;
    },


    show_pop(){
      this.is_show = true;
      let body = document.querySelector('body');
      body.style.height = 100+'vh';
      body.style.overflow = 'hidden';
    },

    open_show(){
      this.is_show = false;
      let body = document.querySelector('body');
      body.style.height = 100+'%';
      body.style.overflow = 'auto';
    },


    nav_swiper(){
      let that = this;
      // setTimeout(()=>{
      //   var mySwiper = new Swiper ('.swiper-container', {
      //     direction: 'horizontal',
      //     loop: that.index_nav.length >1?true:false,
      //     speed: 1500,
      //     autoplay : {
      //         delay:3500,
      //         disableOnInteraction: false,
      //     },
      //
      //     // 如果需要分页器
      //     pagination: {
      //       el: '.swiper-pagination',
      //       clickable :true,
      //     },
      //
      //     // 如果需要前进后退按钮
      //     navigation: {
      //       nextEl: '.swiper-button-next',
      //       prevEl: '.swiper-button-prev',
      //     },
      //   }) ;
      // },20)


      
    },
    
    async get_routine(){ //获取小程序/公众号
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=7&page=1&limit=15').then(res=>{
        that.routine = res.data.data.all[3];
        that.account = res.data.data.all[2];
      })
    },

    async get_logo(){ //获取网站logo
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=25&page=1&limit=15').then(res=>{
        that.web_logo = res.data.data.all[0];
        that.index_logo = res.data.data.all[1];
      })
    },


    async get_nav(){  //获取导航栏
      await this.$axios.get('site/nav_list/0',{}).then(res=>{
        this.nav_list = res.data.data.list;
        let that = this;
        this.nav_list.forEach((val)=>{
          if(val.link == '/'){
            this.nav_img = val.image;
            this.index_nav = val.sub_nav;
           
          }

        })
      })
    },

  },
};
</script>

<style>
  a{text-decoration:unset;color: unset;}
  a:hover{ color: unset;}
  a:visited{color: unset;}
  a:active{color: unset;}
  input{border: 0;}
  body{margin: 0;}
  .nav_color{color: #0BB4E7;}

  ._com-head-box{
   position:fixed;top:0; width:100%; z-index:11;
  }
  ._com-head-height{
    height:7vh;
  }
  .head-pop{
    position:fixed;top:0; width:100%;height:100%;background-color:rgba(0,0,0,0.2);z-index:11;display:flex;justify-content:center;align-items:center;left: 0;
  }

  .nav-index-border-top{
    border-top: 2px solid #FFF;
  }

  .nav-border-top{
    border-top: 2px solid #0BB4E7;
  }

  .swiper-container div{width: 100%;}
  .nav-opac{
    opacity: 0.6;
  }

  ._wechat-box div{
    display: none;
  }

  ._wechat-box:hover div{
    display: block;
  }

  ._swip-next{
    background-image: url('/assets/image/swip-next.png');
  }
  ._swip-pre{
    background-image: url('/assets/image/swip-pre.png');
  }
  ._btn-hover:hover{opacity: 0.4;}
</style>
