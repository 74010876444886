export default{
	'语言切换': '语言切换',
	'网站首页': '网站首页',
	login_signup: '登录/注册',
	'系统消息': '系统消息',
	// 页面名称
	page_title:{
		index: '首页',
		search: '快递查找',
		
		question: '常见问题',
		question_detail: '详情',
		report: '包裹报备',
		about_us: '关于我们',
		estimate: '预估运费结果',
		order_detail: '订单详情',
		user: '个人中心',
		
		addressInfo: '收货地址'
	},
	'注册': '注册',
	'消息列表': '消息列表',
	'请输入查询单号...': '请输入查询单号...',
	'高效准时': '高效准时',
	'细心服务': '细心服务',
	'便携集运': '便携集运',
	'值得信赖': '值得信赖',
	// 首页下单步骤
	'下单步骤': '下单步骤',
	'登录 注册': '登录 注册',
	'点击左上角的“登录”,如果您还没有账号，请在登录页面点击“注册”。': '点击左上角的“登录”,如果您还没有账号，请在登录页面点击“注册”。',
	'点击包裹预报按钮，输入您要发货的快递单号，选择包裹中的商品类别，点击提交。': '点击包裹预报按钮，输入您要发货的快递单号，选择包裹中的商品类别，点击提交。',
	'物流查询': '物流查询',
	'点击“我的订单”，点击订单详情，复制物流单号，返回首页，将订单号粘贴到查询栏，即可查询物流进度。': '点击“我的订单”，点击订单详情，复制物流单号，返回首页，将订单号粘贴到查询栏，即可查询物流进度。',
	warehouse_address: '仓库地址',
	warehouse_address_info: '广东省佛山市南海区丽水市新联工业区工业大道东一路3号航达A仓区',
	recipient_info: '在收件人一栏中标记您的用户名(或 ID_)+城市',
	// 首页集运服务
	'集运服务': '集运服务',
	'包裹预报': '包裹预报',
	'预报您的包裹信息': '预报您的包裹信息',
	'我的订单': '我的订单',
	'查看我的集运订单': '查看我的集运订单',
	'帮助中心': '帮助中心',
	'您想知道的集运常见问题都在这': '您想知道的集运常见问题都在这',
	'关于我们': '关于我们',
	'关于我们的介绍': '关于我们的介绍',
	//运费估算 
	'运费估算': '运费估算',
	'开始预估': '开始预估',
	'已为您查询到线路方案：': '已为您查询到线路方案：',
	'以下结果仅供参考，详情请咨询客服': '以下结果仅供参考，详情请咨询客服',
	'卢布': '卢布',
	'物品限制': '物品限制',
	'重量限制': '重量限制',
	'长度限制': '长度限制',
	//
	'运费': '运费',
	'重量':'重量',
	'首重': '首重',
	'续重': '续重',
	'长度': '长度',
	wide: '宽度',
	height: '高度',
	'请选择': '请选择...',
	'请输入': '请输入...',
	'收货地址': '收货地址',
	search: '查询',
	'地址': '地址',
	'复制': '复制',
	'一键复制': '一键复制',
	recipient: '收件人',
	'电子邮箱': '电子邮箱',
	'密码': '密码',
	'记住密码': '记住密码',
	'找回密码': '找回密码',
	'登录': '登录',
	'验证码': '验证码',
	'获取验证码': '获取验证码',
	'提交': '提交',
	date:'日期',
	country: '国家',
	region:'地区',
	city: '城市',
	'下一步': '下一步',
	
	//快递查询
	'当前状态': '当前状态',
	//登录
	'欢迎登录': '欢迎登录',
	//注册
	'验证码已发送至您的邮箱，请注意': '验证码已发送至您的邮箱，请注意',
	'新密码': '新密码',
	'请输入新密码': '请输入新密码...',
	'请确认新密码': '请确认新密码',
	'再次输入您的新密码': '再次输入您的新密码',
	'返回登录': '返回登录',
	'登录密码': '登录密码',
	'确认密码': '确认密码',
	'再次输入您的密码': '再次输入您的密码',
	//常见问题 / 详情
	'返回': '返回',
	'上一篇': '上一篇',
	next: '下一篇',
	breadcumb: '当前位置：首页 > 常见问题 > 详情',
	//包裹报备
	'单个报备': '单个报备',
	'批量报备': '批量报备',
	'报备记录': '报备记录',
	'包裹单号': '包裹单号',
	'货物类别': '货物类别',
	'添加包裹': '添加包裹',
	'每行输入一个单号，可进行批量查询...': '每行输入一个单号，可进行批量查询...',
	'预报时间': '预报时间',
	'物品类型': '物品类型',
	//关于我们 
	about_us: {
		warehouse_address_title: '仓库地址',
		dizhi: '地址',
		address_info: '广东省佛山市海南区丽水市新联工业区工业大道东一路3号航达A仓区',
		contact_info: '联系信息',
		contact_user: '联系人',
	},
	//订单详情
	order_detail: {
		order_number: '订单号',
		return_my_center: '返回个人中心',
		send: '寄',
		receive: '收',
		status: '状态',
		logistics_number: '物流单号',
		route: '路线',
		
		rukucang: '入库仓',
		package_info: '包装信息',
		weight:'体重',
		long: '长',
		wide: '宽度',
		height: '高',
	},
	'打包时间': '打包时间',
	//个人中心
	user_center: {
		revise_username: '更改用户信息',
		youji_dizhi: '海外收件地址',
		detail_address: '详细地址',
		my_order: '我的订单',
		search_order_keyword: '搜索订单关键词...',
		all_order_status: '所有订单',
		order_status_10: '打包中',
		order_status_20: '已打包',
		order_status_30: '已发货',
		order_status_40: '国外签收',
		order_status_50: '已完成',
		order_status_60: '国外上架',
		order_status_85: '已合包',
		order_number: '订单号',
		
		route: '路线',
		seach_btn: '物流查询',
		more_btn: '更多',
		message:'订单留言',
	},
	'打包单号': '打包单号',
	'创建时间': '创建时间',
	//
	'请输入邮箱': '请输入邮箱',
	'请输入验证码': '请输入验证码',
	'请输入登录密码': '请输入登录密码',
	'请输入确认密码': '请输入确认密码',
	'完善您的收货地址': '完善您的收货地址',
	'地址填写后不能更改，请谨慎填写': '地址填写后不能更改，请谨慎填写',
	'收件者姓名': '收件者姓名',
	'联系电话': '联系电话',
	'state': '国家/地区',
	'确认提交': '确认提交',
	'确认收货地址': '确认收货地址',
	'收件人': '收件人',
	'cancel': '取消',
	'confirm': '确认',
	'注册成功': '注册成功',
	'请先选择国家或地区': '请先选择国家或地区',
	'请输入账号密码': '请输入账号密码',
	'用户昵称': '用户昵称',
	'更改': '更改',
	'旧密码': '旧密码',
	'确认新密码': '确认新密码',
	'更改密码': '更改密码',
	'更改用户信息': '更改用户信息',
	'退出登录确认': '退出登录确认',
	'您确定退出当前账户吗？': '您确定退出当前账户吗？',
	'消息内容': '消息内容',
	'时间': '时间',
	'请输入快递单号': '请输入快递单号',
	'报备成功': '报备成功',
	'复制成功': '复制成功',
	'复制失败': '复制失败',
	'退出登录': '退出登录',
	'请先登录': '请先登录',
	'common': '共',
	'package': '个包裹',
	'全部已读': '全部已读',
	'暂无数据': '暂无数据',
	'请输入查询单号': '请输入查询单号',
	'包裹状态': '包裹状态',
	'all': '全部',
	'待入库': '待入库',
	'已入库' : '已入库',
	'请登录' : '请登录',
	'你没有权限' : '你没有权限',
	'我的包裹' : '我的包裹',
	'物品名称' : '物品名称',
	'包裹备注' : '包裹备注',
	'请输入包裹备注' : '请输入包裹备注',
	'入库图片' : '入库图片',
	'搜索包裹关键词': '搜索包裹关键词',
	'报备备注': '报备备注',
	'订单留言': '订单留言',
	'请输入内容': '请输入内容',
	'send': '发送',
	'聊天记录': '聊天记录',
	'请输入发送内容': '请输入发送内容',
	'是否超大包裹': '是否超大包裹',
	yes: '是',
	no: '否',
	'异常原因': '异常原因',
	'包裹类型': '包裹类型',
	'请阅读并同意协议': '请阅读并同意协议',
	'我不同意': '我不同意',
	'同意并继续': '同意并继续',
	'入库备注': '入库备注',
	'申报价值': '申报价值',
	'是否打木架': '是否打木架',
	'木架费': '木架费',
	'流程不符，请先注册': '流程不符，请先注册',
	'到付金额': '到付金额',
	'包裹重量': '包裹重量',
	
	'消息':'消息',
	'已打包':'已打包',
	'已合包':'已合包',
	'已发货':'已发货',
	'国外已签收':'国外已签收',
	'更改用户名':'更改用户名',
	'所有订单':'所有订单',
	'申请打包':'申请打包',
	'地址管理':'地址管理',
	'查询':'查询',
	'国外签收':'国外签收',
	'国外上架':'国外上架',
	'已完成':'已完成',
	'搜索订单关键词':'搜索订单关键词',
	'全部':'全部',
	'状态':'状态',
	'路线':'路线',
	'总重量':'总重量',
	'添加地址':'添加地址',
	'删除':'删除',
	'修改地址':'修改地址',
	'删除地址':'删除地址',
	'删除成功':'删除成功',
	'新建地址':'新建地址',
	'国家/地区':'国家/地区',
	'城市':'城市',
	'详细地址':'详细地址',
	'修改密码':'修改密码',
	'订单详情':'订单详情',
	'订单号':'订单号',
	'寄':'寄',
	'收':'收',
	'查询物流':'查询物流',
	'物流单号':'物流单号',
	'入库仓':'入库仓',
	'打包图片':'打包图片',
	'支付时间':'支付时间',
	'合包时间':'合包时间',
	'发货时间':'发货时间',
	'到达国外仓时间':'到达国外仓时间',
	'签收时间':'签收时间',
	'打包信息':'打包信息',
	'长':'长',
	'宽度':'宽度',
	'高':'高',
	'体重':'体重',
	'未登录':'未登录',
	'取消':'取消',
	'确认':'确认',
	'支付凭证':'支付凭证',
	'包裹认领': '包裹认领',
	'管理我的收货地址': '管理我的收货地址',
	'申请包裹合包寄出': '申请包裹合包寄出',
	'已选择包裹': '已选择包裹',
	'快递单号': '快递单号',
	'海外收货地址': '海外收货地址',
	'更换':'更换',
	'请选择收货地址':'请选择收货地址',
	'选择': '选择',
	'请选择路线':'请选择路线',
	'备注':'备注',
	'请输入备注': '请输入备注',
	'请选择包裹':'请选择包裹',
	'操作成功':'操作成功',
	'去打包': '去打包',
	'未设置': '未设置',
	'省/州': '省/州',
	'邮编': '邮编',
	'长宽高': '长宽高',
	'报备时间': '报备时间',
	'入库时间': '入库时间',
	'添加成功': '添加成功',
	'提交成功': '提交成功',
	'请重新选择国家或地区': '请重新选择国家或地区',
	'大包单号': '大包单号',
	'点击可查看包裹轨迹': '点击可查看包裹轨迹',
	'你的ID': '你的ID',
	'请填写ID号段为900001-999999范围内的数字': '请填写ID号段为900001-999999范围内的数字',
	'请输入你的ID': '请输入你的ID',
	'当前': '当前',
	'认领': '认领',
	'请输入包裹单号': '请输入包裹单号',
	ru_lang:'Русский',
	zh_lang:'简体中文',
	en_lang:'English',
} 