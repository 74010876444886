export default{
	'语言切换': 'Language switching',
	'网站首页': 'Website homepage',
	login_signup: 'Login/Register',
	'系统消息': 'System messages',
	// 页面名称
	page_title:{
		index: 'Home',
		search: 'Express Search',
		
		question: 'Frequently asked question',
		question_detail: 'Details',
		report: 'Package reporting',
		about_us: 'About us',
		estimate: 'Estimated Freight Results',
		order_detail: 'Order details',
		user: 'Personal Center',
		
		addressInfo: 'Address'
	},
	'注册': 'Register',
	'消息列表': 'Message list',
	'请输入查询单号...': 'Please enter the inquiry number...',
	'高效准时': 'Efficient and punctual',
	'细心服务': 'Careful service',
	'便携集运': 'Portable consolidation',
	'值得信赖': 'Trustworthy',
	// 首页下单步骤
	'下单步骤': 'Order Steps',
	'登录 注册': 'Login Register',
	'点击左上角的“登录”,如果您还没有账号，请在登录页面点击“注册”。': 'Click on "Login" in the upper left corner. If you do not already have an account, please click on "Register" on the login page.',
	'点击包裹预报按钮，输入您要发货的快递单号，选择包裹中的商品类别，点击提交。': 'Click on the package forecast button, enter the tracking number you want to ship, select the product category in the package, and click submit.',
	'物流查询': 'Logistics Query',
	'点击“我的订单”，点击订单详情，复制物流单号，返回首页，将订单号粘贴到查询栏，即可查询物流进度。': "Click on 'My Order', click on the order details, copy the logistics tracking number, return to the homepage, and paste the order number into the query bar to check the logistics progress.",
	warehouse_address: 'Warehouse address',
	warehouse_address_info: '广东省佛山市南海区丽水市新联工业区工业大道东一路3号航达A仓区',
	recipient_info: '在收件人一栏中标记您的用户名(或 ID_)+城市',
	// 首页集运服务
	'集运服务': 'Consolidation service',
	'预报您的包裹信息': 'Forecast your package information',
	'我的订单': 'My Order',
	'查看我的集运订单': 'View my consolidation orders',
	'帮助中心': 'Help',
	'您想知道的集运常见问题都在这': 'The common problems you want to know about container transportation are all here',
	'关于我们': 'About us',
	'关于我们的介绍': 'About our introduction',
	//运费估算 
	'运费估算': 'Freight estimation',
	'开始预估': 'Start estimation',
	'已为您查询到线路方案：': 'We have found the route plan for you：',
	'以下结果仅供参考，详情请咨询客服': 'The following results are for reference only. Please consult customer service for details',
	'卢布': 'rouble',
	'物品限制': 'Item Restrictions',
	'重量限制': 'Weight limitations',
	'长度限制': 'Length limit',
	//
	'运费': 'Freight',
	'重量':'Weight',
	'首重': 'First weight',
	'续重': 'Continuation weight',
	'长度': 'Length',
	wide: 'Width',
	height: 'Height',
	'请选择': 'Please select...',
	'请输入': 'Enter...',
	'收货地址': 'Address',
	search: 'Query',
	'地址': 'Address',
	'复制': 'Copy',
	'一键复制': 'One click copy',
	recipient: 'Recipient',
	'电子邮箱': 'E-mail',
	'密码': 'Password',
	'记住密码': 'Remember password',
	'找回密码': 'Retrieve password',
	'登录': 'Login',
	'验证码': 'Code',
	'获取验证码': 'Obtain verification code',
	'提交': 'Submit',
	date:'Date',
	country: 'Country',
	region:'Area',
	city: 'City',
	'下一步': 'Next step',
	
	//快递查询
	'当前状态': 'Current state',
	//登录
	'欢迎登录': 'Welcome to login',
	//注册
	'验证码已发送至您的邮箱，请注意': 'The verification code has been sent to your email, please note',
	'新密码': 'Password',
	'请输入新密码': 'Please enter a new password...',
	'请确认新密码': 'Please confirm the new password',
	'再次输入您的新密码': 'Enter your new password again',
	'返回登录': 'Return to login',
	'登录密码': 'Login password',
	'确认密码': 'Confirm password',
	'再次输入您的密码': 'Enter your password again',
	//常见问题 / 详情
	'返回': 'Return',
	'上一篇': 'Previous article',
	next: 'Next article',
	breadcumb: 'Current location：Home > Frequently asked question > Details',
	//包裹报备
	'单个报备': 'Single report',
	'批量报备': 'Batch reporting',
	'报备记录': 'Reporting records',
	'包裹单号': 'Parcel tracking number',
	'货物类别': 'Category of goods',
	'添加包裹': 'Add package',
	'每行输入一个单号，可进行批量查询...': 'Enter one tracking number per line for batch queries...',
	'预报时间': 'Forecast time',
	'物品类型': 'Item type',
	//关于我们 
	about_us: {
		warehouse_address_title: 'Warehouse address',
		dizhi: 'Address',
		address_info: '广东省佛山市海南区丽水市新联工业区工业大道东一路3号航达A仓区',
		contact_info: 'Contact Information',
		contact_user: 'Contacts',
	},
	//订单详情
	order_detail: {
		order_number: 'Order No',
		return_my_center: 'Return to personal center',
		send: 'Send',
		receive: 'Put away',
		
		status: 'State',
		logistics_number: 'Logistics tracking number',
		route: 'Route',
		
		rukucang: 'Warehousing warehouse',
		package_info: 'Packaging',
		
		weight:'Weight',
		long: 'Length',
		wide: 'Width',
		height: 'Height',
	},
	'打包单号': 'Packing list number',
	'创建时间': 'Creation time',
	'打包时间': 'Packaging time',
	//个人中心
	user_center: {
		youji_dizhi: 'Overseas shipping address',
		detail_address: 'Address',
		my_order: 'My Order',
		search_order_keyword: 'Search order keywords...',
		all_order_status: 'All orders',
		order_status_10: 'Packaging',
		order_status_20: 'Packaged',
		order_status_30: 'Shipped',
		order_status_40: 'Foreign receipt',
		order_status_50: 'Completed',
		order_status_60: 'Overseas listing',
		order_status_85: 'Merge packages',
		order_number: 'Order No',
		route: 'Route',
		seach_btn: 'Logistics query',
		more_btn: 'More',
		message:'message',
	},
	//
	'请输入邮箱': 'Please enter your email address',
	'请输入验证码': 'Please enter the verification code',
	'请输入登录密码': 'Please enter your login password',
	'请输入确认密码': 'Please enter a confirmation password',
	'完善您的收货地址': 'Improve your shipping address',
	'地址填写后不能更改，请谨慎填写': 'The address cannot be changed after filling it out. Please fill it out carefully',
	'收件者姓名': 'Recipient Name',
	'联系电话': 'Telephone',
	'state': 'Country/Agea',
	'确认提交': 'Confirm submission',
	'确认收货地址': 'Confirm shipping address',
	'收件人': 'Recipient',
	'cancel': 'Cancellation',
	'confirm': 'Confirm',
	'注册成功': 'Login was successful',
	'请先选择国家或地区': 'Please select a country or region first',
	'请输入账号密码': 'Please enter the account password',
	'用户昵称': 'User nickname',
	'更改': 'Change',
	'旧密码': 'Old password',
	'确认新密码': 'Confirm new password',
	'更改密码': 'Change password',
	'更改用户信息': 'Change user information',
	'退出登录确认': 'Exit login confirmation',
	'您确定退出当前账户吗？': 'Are you sure to exit the current account?',
	'消息内容': 'Message content',
	'时间': 'Time',
	'请输入快递单号': 'Please enter the courier number',
	'报备成功': 'Successfully reported',
	'复制成功': 'Replicating success',
	'复制失败': 'Copy failed',
	'退出登录': 'Log out of login',
	'请先登录': 'Please log in first',
	'common': 'common',
	'package': 'Packages',
	'全部已读': 'All read',
	'暂无数据': 'There is currently no data available',
	'请输入查询单号': 'Please enter the inquiry number',
	'包裹状态': 'Package status',
	'all': 'whole',
	'待入库': 'To be stored',
	'已入库' : 'Received',
	'请登录' : 'Please log in',
	'你没有权限' : "You don't have permission",
	'我的包裹' : 'My package',
	'物品名称' : 'Item name',
	'包裹备注' : 'Package remarks',
	'请输入包裹备注' : 'Please enter the package note',
	'入库图片' : 'Storage image',
	'搜索包裹关键词': 'Search for package keywords',
	'报备备注': 'Reporting remarks',
	'订单留言': 'Order message',
	'请输入内容': 'Please enter content',
	'send': 'Send',
	'聊天记录': 'Chat log',
	'请输入发送内容': 'Please enter the sending content',
	'是否超大包裹': 'Whether oversized package',
	yes: 'Yes',
	no: 'No',
	'异常原因': 'Anomalous cause',
	'包裹类型': 'Package Type',
	'请阅读并同意协议': 'Please read and agree to the agreement',
	'我不同意': "I don't agree",
	'同意并继续': 'Agree and proceed',
	'入库备注': 'Warehousing remarks',
	'申报价值': 'Declared value',
	'是否打木架': 'Whether a wooden frame is needed',
	'木架费': 'Timbering cost',
	'流程不符，请先注册': 'Process discrepancy, please register first',
	'到付金额': 'Amount collected',
	'包裹重量': 'Parcel weight',
	'未设置': 'Is not set',
	'消息':'message',
	'已打包':'Packaged',
	'已合包':'Merged',
	'已发货':'Shipped',
	'国外已签收':'Signed for',
	'更改用户名':'rename',
	'所有订单':'All orders',
	'包裹预报':'Package forecast',
	'申请打包':'Apply for packaging',
	'地址管理':'Address Management',
	'查询':'query',
	'国外签收':'Signed for',
	'国外上架':'grounding',
	'已完成':'Completed',
	'搜索订单关键词':'Search order keywords',
	'全部':'All',
	'状态':'status',
	'路线':'route',
	'总重量':'total weight',
	'添加地址':'Add address',
	'删除':'delete',
	'修改地址':'Modify Address',
	'删除地址':'Del Address',
	'删除成功':'Delete successful',
	'新建地址':'New Address',
	'国家/地区':'Country/Region',
	'城市':'City',
	'详细地址':'Address',
	'修改密码':'Change password',
	'订单详情':'Order details',
	'订单号':'Order No.',
	'寄':'send',
	'收':'put',
	'查询物流':'Query logistics',
	'物流单号':'Logistics numbers',
	'入库仓':'Warehouse',
	'打包图片':'Packaging images',
	'支付时间':'time of payment',
	'合包时间':'merge package time',
	'发货时间':'delivery time',
	'到达国外仓时间':'arrival time',
	'签收时间':'Signing time',
	'打包信息':'information',
	'长':'length',
	'宽度':'width',
	'高':'height',
	'体重':'weight',
	'未登录':'Not Logged In',
	'取消':'cancel',
	'确认':'confirm',
	'包裹认领': 'Parcel claim',
	'管理我的收货地址': 'Manage my shipping address',
	'申请包裹合包寄出': 'Apply for package consolidation and mailing out',
	'已选择包裹': 'Selected package',
	'快递单号': 'Tracking number',
	'海外收货地址': 'Overseas delivery address',
	'更换':'switch',
	'请选择收货地址':'Please select a delivery address',
	'选择': 'Select',
	'请选择路线':'Please select a route',
	'备注':'remark',
	'请输入备注': 'Please enter remarks',
	'请选择包裹':'Please select a package',
	'操作成功':'Success',
	'去打包': 'Go pack',
	'省/州': 'Province/State',
	'邮编': 'Postcode',
	'长宽高': 'length, width and height',
	'报备时间': 'Filing time',
	'入库时间': 'warehouse entry time',
	'添加成功': 'successfully added',
	'提交成功': 'submit successfully',
	'请重新选择国家或地区': 'Please select another country or region',
	'大包单号': 'Big bag number',
	'点击可查看包裹轨迹': 'Click to view the package track',
	'你的ID': 'Your ID',
	'请填写ID号段为900001-999999范围内的数字': 'Please enter the number in the range of 900001-999999',
	'请输入你的ID': 'Please enter your ID',
	'当前': 'at present',
	'认领': 'Claim',
	'请输入包裹单号': 'Please enter the parcel number',
	ru_lang:'Русский',
	zh_lang:'简体中文',
  en_lang:'English',
} 