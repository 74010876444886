import Cookies from 'js-cookie';
// 设置setCookies；
// setToken
export const setCookies = (key, val, cookieExpires) => {
    Cookies.set(key, val, { expires: cookieExpires || 1 })
}
// 获取getCookies；
// getToken
export const getCookies = (key) => {
    return Cookies.get(key)
}

export const removeCookies = (key) => {
    return Cookies.remove(key)
}

export const checkLogin = ()=>{
    let expires_time = Cookies.get('web-expires_time');
    let time = Math.round(new Date() / 1000);
    if(expires_time < time){
        Cookies.remove('web-expires_time');
        Cookies.remove('web-token');
        Cookies.remove('uid');
    }
}