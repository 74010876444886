import axios from "axios";
import { getCookies, removeCookies } from'../libs/util';
import { Message } from "view-design";
import router from '@/router';
axios.defaults.headers.common['Authori-zation'] = 'Bearer '+ getCookies('web-token') || '';
const service = axios.create({
    // baseURL: 'http://www.hangda.com/api',
    baseURL: 'https://api.kmaonan.com/api',
    timeout: 100000, // 请求超时时间
    withCredentials: true,
    headers: {
        "Content-Type": "application/json; charset=UTF-8;",
        "think-lang": localStorage.getItem('think-lang') || 'ru'
    }
})
service.interceptors.response.use(
    response => {
        let status = response.data ? response.data.status : 0
        const code = status
        if(code == 200){
            return response.data
        } else if (code == 400 || code == 500 || code == 400011 || code == 400012){
            return Promise.reject(response.data || { msg: '未知错误' })
        } else if (code >= 10000 && code <= 20000){
            return Promise.reject(response.data || { msg: '未知错误' })
        } else if (code == 410000 || code == 410001 || code == 410002) {
            localStorage.clear()
            removeCookies('web-token')
            removeCookies('web-expires_time')
            removeCookies('uid')
            router.replace({ path: '/login' })
        }
    },error =>{
        Message.error(error.msg)
        return Promise.reject(error)
    }
)
export default service