<template>
  <div>
    <!-- 我们的服务 -->
    <div id="server" class="_pc-pad-y-60 _pc-pad-x-260 wow fadeInDown">
      <div class="_flex-row-bet _pc-mar-bottom-45">
        <div class="_flex-row">
          <div
            class="_pc-font-42 _b _pc-mar-right-20"
            style="color: #0bb4e7; line-height: 0.9"
          >
            SERVICE
          </div>
          <div class="_pc-font-28" style="align-self: flex-end">我们的服务</div>
        </div>

        <div class="_pc-font-16 _grey-99" style="align-self: flex-end">
          O N E&nbsp;&nbsp;S T O P&nbsp;&nbsp;S E R V I C E
        </div>
      </div>

      <div class="_flex-row-bet-mid">
        <img
          class="_pc-w-730 _pc-mar-right-85"
          :src="serve_list.image_input"
          alt=""
        />

        <div class="_flex-1">
          <div class="title-color _pc-font-24 _pc-mar-bottom-20">寄货服务</div>
          <div
            class="_pc-font-16 _pc-mar-bottom-40 _black"
            style="line-height: 28px"
            v-html="serve_list.content"
          ></div>
          <div class="title-color _pc-font-24 _pc-mar-bottom-25">打包方式</div>
          <div class="_flex-row-bet-mid">
            <div class="_pc-font-16 _flex-col-mid">
              <img
                class="_pc-w-55 _pc-mar-bottom-10"
                src="@/assets/image/yima/index/pack-01.png"
                alt=""
              />
              <div>拆分包裹</div>
            </div>
            <img
              class="_pc-w-25"
              src="@/assets/image/yima/index/right-ico-01.png"
              alt=""
            />
            <div class="_pc-font-16 _flex-col-mid">
              <img
                class="_pc-w-55 _pc-mar-bottom-10"
                src="@/assets/image/yima/index/pack-02.png"
                alt=""
              />
              <div>合并包裹</div>
            </div>
            <img
              class="_pc-w-25"
              src="@/assets/image/yima/index/right-ico-01.png"
              alt=""
            />
            <div class="_pc-font-16 _flex-col-mid">
              <img
                class="_pc-w-55 _pc-mar-bottom-10"
                src="@/assets/image/yima/index/pack-03.png"
                alt=""
              />
              <div>包裹拍照</div>
            </div>
            <img
              class="_pc-w-25"
              src="@/assets/image/yima/index/right-ico-01.png"
              alt=""
            />
            <div class="_pc-font-16 _flex-col-mid">
              <img
                class="_pc-w-55 _pc-mar-bottom-10"
                src="@/assets/image/yima/index/pack-04.png"
                alt=""
              />
              <div>包裹重量图</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 关于一马 -->

    <div
      class="_pc-pad-y-60 _flex-row-bet grey-bg _pc-pad-x-260 wow fadeInDown"
      :class="scrollTop > 800 ? 'animaLeft' : ''"
    >
      <div class="_pc-mar-right-65 _flex-col-mid">
        <div class="_flex-row _pc-mar-bottom-40" style="align-self: flex-start">
          <div
            class="_pc-font-42 _b _pc-mar-right-20"
            style="color: #0bb4e7; line-height: 0.9"
          >
            ABOUT
          </div>
          <div class="_pc-font-28" style="align-self: flex-end">关于一马</div>
        </div>

        <div
          class="_pc-font-16 _pc-mar-bottom-50 _black"
          style="line-height: 28px"
          v-html="about_list.content"
        ></div>

        <router-link to="/about" style="align-self: flex-start">
          <div
            class="
              _btn-p-hover
              _pc-pad-x-30 _pc-pad-y-15
              _flex-row-mid
              title-color
              _pc-font-16
            "
            style="border: 1px solid #0bb4e7"
          >
            关于我们
            <img
              class="_pc-w-20 _pc-mar-left-10"
              src="@/assets/image/yima/index/right-ico-02.png"
              alt=""
            />
          </div>
        </router-link>
      </div>

      <img class="_pc-w-750" :src="about_list.image_input" alt="" />
    </div>

    <!-- 路线介绍 -->

    <div
      class="_pc-pad-y-60 _pc-pad-x-260 wow fadeInDown"
      v-if="route_list.length != 0"
      :class="scrollTop > 1800 ? 'animaRight' : ''"
    >
      <div class="_flex-row-bet _pc-mar-bottom-45">
        <div class="_flex-row">
          <div
            class="_pc-font-42 _b _pc-mar-right-20"
            style="color: #0bb4e7; line-height: 0.9"
          >
            ROUTE
          </div>
          <div class="_pc-font-28" style="align-self: flex-end">路线介绍</div>
        </div>

        <div class="_pc-font-16 _grey-99" style="align-self: flex-end">
          R O U T E &nbsp;&nbsp; I N T R O D U C T I O N
        </div>
      </div>

      <div class="_flex-row-bet-mid _flex-wrap">
        <div
          class="
            _pc-mar-right-35
            _flex-1
            _pc-pad-left-30 _pc-pad-right-35
            _flex-row
            _pos-rel
          "
          style="margin-bottom: 10%"
          v-for="(item, index) in route_list"
          :key="index"
          v-show="show_all ? index <= route_list.length : index <= 3"
        >
          <img
            class="_pc-w-230 _pc-mar-right-35 _pos-rel _z-index-1"
            :src="item.logo"
            alt=""
          />

          <div class="_flex-col-mid _pos-rel _z-index-1 _pc-pad-top-35">
            <div
              class="title-color _pc-font-28 _pc-mar-bottom-20 _pc-pad-top-20"
              style="align-self: flex-start"
            >
              {{ item.name }}
            </div>
            <div
              class="_pc-font-16 _pc-mar-bottom-35 _black _line-4"
              style="line-height: 28px"
            >
              {{ item.length_limit }}{{ item.style }}{{ item.articles_limit }}
            </div>
            <router-link to="/estimated_price" style="align-self: flex-start">
              <div
                class="
                  _btn-p-hover
                  _pc-pad-x-30 _pc-pad-y-15
                  _flex-row-mid
                  title-color
                  _pc-font-16
                "
                style="border: 1px solid #0bb4e7; align-self: flex-start"
              >
                预估运费
                <img
                  class="_pc-w-20 _pc-mar-left-10"
                  src="@/assets/image/yima/index/right-ico-02.png"
                  alt=""
                />
              </div>
            </router-link>
          </div>

          <img
            class="_pos-abs-left _w-max _h-max"
            style="top: 10%"
            src="@/assets/image/index-route-bg.png"
            alt=""
          />
        </div>
      </div>
      <div class="_flex-row-center-mid">
        <div
          class="
            _btn-p-hover
            _pc-pad-x-30 _pc-pad-y-15
            _flex-row-mid
            title-color
            _pc-font-16
          "
          style="
            cursor: pointer;
            border: 1px solid #0bb4e7;
            align-self: flex-start;
          "
          v-if="!show_all"
          @click="show_all = !show_all"
        >
          展示更多
        </div>

        <div
          class="
            _btn-p-hover
            _pc-pad-x-30 _pc-pad-y-15
            _flex-row-mid
            title-color
            _pc-font-16
          "
          style="
            cursor: pointer;
            border: 1px solid #0BB4E7;
            align-self: flex-start;
          "
          v-if="show_all"
          @click="show_all = !show_all"
        >
          折叠
        </div>
      </div>
    </div>

    <!-- 下单流程 -->

    <div
      class="
        _pc-pad-y-60 _pc-pad-x-260
        process-bg
        background-max
        wow
        fadeInDown
      "
      :class="scrollTop > 2500 ? 'animaLeft' : ''"
    >
      <div class="_flex-row-bet _pc-mar-bottom-45">
        <div class="_flex-row">
          <div
            class="_pc-font-42 _b _pc-mar-right-20"
            style="color: #0bb4e7; line-height: 0.9"
          >
            PROCESS
          </div>
          <div class="_pc-font-28 _white" style="align-self: flex-end">
            下单流程
          </div>
        </div>

        <div class="_pc-font-16 _grey-99" style="align-self: flex-end">
          O R D E R &nbsp;&nbsp; P R O C E S S
        </div>
      </div>

      <div class="_flex-row-mid _pc-font-20">
        <div
          class="_flex-col-mid _white _flex-1 index-pro"
          v-for="(item, index) in list"
          :key="index"
        >
          <img class="_pc-w-50" :src="item.image_input[0]" alt="" />

          <div class="_flex-row-center-mid _pos-rel _w-max">
            <img
              class="_pc-pad-y-20 _pc-w-30"
              src="@/assets/image/pro-ico.png"
              alt=""
            />
            <div class="_border-top _pos-abs-right pro-ico _w-max"></div>
          </div>

          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>

    <!-- 客户好评 -->

    <!-- <div
      class="_pc-pad-y-60 _pc-pad-x-260 wow fadeInDown"
      v-if="comm_list.length != 0"
      :class="scrollTop > 3000 ? 'anima' : ''"
    >
      <div class="_flex-row-bet _pc-mar-bottom-45">
        <div class="_flex-row">
          <div
            class="_pc-font-42 _b _pc-mar-right-20"
            style="color: #0bb4e7; line-height: 0.9"
          >
            EVALUATE
          </div>
          <div class="_pc-font-28" style="align-self: flex-end">客户好评</div>
        </div>
        <div class="_pc-font-16 _grey-99" style="align-self: flex-end">
          C U S T O M E R &nbsp;&nbsp; P R A I S E
        </div>
      </div>

      <div class="evaluate-box">
        <div class="_flex-row" v-for="(item, index) in comm_list" :key="index">
          <img
            class="_border-circle _pc-mar-right-15 _pc-w-65"
            style="height: 3.38542vw"
            :src="item.headimgurl"
            alt=""
          />

          <div class="_pc-font-20 _black _w-max" style="overflow: hidden">
            <div class="_pc-mar-bottom-10 _pc-font-20">{{ item.nickname }}</div>
            <div
              class="grey-bg _pc-pad-x-20 _pc-pad-y-15 _pc-font-16 _h-max"
              style="border-radius: 8px"
            >
              <div class="_pc-mar-bottom-10 _line-1" v-if="item.express_name">
                路线：{{ item.area_express_name }}
              </div>
              <div class="_pc-mar-bottom-15 _line-3" style="line-height: 28px">
                {{ item.content }}
              </div>

              <div class="evaluate-img">
                <img
                  class="_pc-w-90 _pc-mar-bottom-5"
                  v-for="(val, key) in item.images"
                  :key="key"
                  :src="val"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 集运动态 -->

    <div
      class="_pc-pad-y-60 grey-bg _pc-pad-x-260 wow fadeInDown"
      :class="scrollTop > 3000 ? 'animate__animated animate__fadeInUp' : ''"
    >
      <div class="_flex-row-bet _pc-mar-bottom-45">
        <div class="_flex-row">
          <div
            class="_pc-font-42 _b _pc-mar-right-20"
            style="color: #0bb4e7; line-height: 0.9"
          >
            NEWS
          </div>
          <div class="_pc-font-28" style="align-self: flex-end">集运动态</div>
        </div>
        <div class="_pc-font-16 _grey-99" style="align-self: flex-end">
          S E T &nbsp;&nbsp; D Y N A M I C
        </div>
      </div>

      <div class="_flex-row" v-if="new_list.length != 0">
        <router-link
          :to="{
            path: '/newList/detaile',
            query: {
              index: JSON.stringify(0),
              page: JSON.stringify(page),
              keyword: JSON.stringify(3),
            },
          }"
        >
          <div class="_pc-mar-right-35 news-hover">
            <img
              class="_pc-w-610 _pc-h-300"
              style="vertical-align: bottom"
              :src="new_list[0].image_input[0]"
              alt=""
            />
            <div
              class="
                _w-max
                _pc-pad-x-25 _pc-pad-top-30 _pc-pad-bottom-25
                _flex-row-bet-mid
                _white-bg
              "
            >
              <div>
                <div class="_pc-font-22 _pc-mar-bottom-15">
                  {{ new_list[0].title }}
                </div>
                <div class="_grey-99">{{ new_list[0].add_time }}</div>
              </div>
              <img
                class="_pc-w-50"
                src="@/assets/image/news-right.png"
                alt=""
              />
            </div>
          </div>
        </router-link>

        <div class="_flex-col-bet _w-max">
          <router-link
            class="_w-max"
            :to="{
              path: '/newList/detaile',
              query: {
                index: JSON.stringify(index),
                page: JSON.stringify(page),
                keyword: JSON.stringify(3),
              },
            }"
            v-for="(item, index) in new_list"
            :key="index"
            v-show="index != 0"
          >
            <div class="_flex-row-mid news-hover _w-max">
              <img
                class="_pc-mar-right-30 _pc-w-150 _pc-h-90"
                :src="item.image_input[0]"
                alt=""
              />
              <div class="_w-max">
                <div class="_flex-row-bet-mid _pc-mar-bottom-15">
                  <div class="_pc-font-20 _black _b _line-1">
                    {{ item.title }}
                  </div>
                  <div
                    class="_grey-99 _pc-font-14"
                    style="align-self: flex-end"
                  >
                    {{ item.add_time }}
                  </div>
                </div>
                <div class="_grey-99 _pc-font-16" style="line-height: 25px">
                  {{ item.synopsis }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div
        class="_w-max _flex-row-center-mid _pc-mar-top-45"
        v-if="new_list.length != 0"
      >
        <router-link to="/newList" style="align-self: flex-start">
          <div
            class="
              _btn-p-hover
              _pc-pad-x-30 _pc-pad-y-15
              _flex-row-mid
              title-color
              _pc-font-16
            "
            style="border: 1px solid #0bb4e7; align-self: flex-start"
          >
            查看更多
            <img
              class="_pc-w-20 _pc-mar-left-10"
              src="@/assets/image/yima/index/right-ico-02.png"
              alt=""
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexPage",
  data() {
    return {
      title: "一马快递--广州一马快递有限公司",
      keyWords: "一马，一马集运",
      description: `一马快递致力于为海外用户提供优质便捷的国内购物体验，一次选择，终身服务。
      提供中国至全球范围内的国际快递服务，免费仓储，代收货物，集包邮递等众多服务。
      通过我们您可以在海外购买到国内任何网站的商品，包括家人、朋友之间的私人物品寄送。
      通过我们可以大大节省国际物流的运费，得到比较稳妥合适的打包，更顺利安全的收到包裹`,
      page: {
        pageNum: 1,
        pageSize: 5, //每页条数
        currentPage: 1, //页码
      },
      comm_list: [], //用户评价列表
      route_list: [], //路线列表
      new_list: [], //新闻列表
      list: [], //下单流程
      serve_list: {}, //我们的服务
      about_list: {}, //关于亚速通
      show_all: false,
      scrollTop: 0,
    };
  },
  metaInfo() {
    return {
      title: this.title, // set a title
      meta: [
        {
          // set meta
          name: "keyWords",
          content: this.keyWords,
        },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },

  beforeMount() {
    this.get_serve();
  },
  mounted() {
    this.get_comm();
    this.get_route();
    this.get_news();
    this.get_process();
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },

    async get_serve() {
      //获取我们的服务/关于亚速通
      let that = this;
      await that.$axios
        .get(
          'site/get_site_newList?page=1&limit=5&cid=5',
          {}
        )
        .then((res) => {
          that.serve_list = res.data.data.list[1];
          that.about_list = res.data.data.list[0];
        });
    },

    async get_comm() {
      //获取评论
      await this.$axios
        .get(
          'site/get_site_comment?page=1&limit=0',
          {}
        )
        .then((res) => {
          res.data.data.featured.forEach((val, key) => {
            if (key <= 5) {
              this.comm_list.push(val);
            }
          });
        });
    },

    async get_route() {
      //获取路线
      let that = this;
      await that.$axios
        .get('express/list', {
          page: 2,
          limit: 11,
        })
        .then((res) => {
          res.data.data.list.forEach((val, key) => {
            if (key <= 99) {
              that.route_list.push(val);
            }
          });
        });
    },

    async get_news() {
      //获取新闻列表
      let that = this;
      await that.$axios
        .get(
          'site/get_site_newList?page=1&limit=5&cid=3',
          {}
        )
        .then((res) => {
          res.data.data.all.forEach((val) => {
            if (val.is_hot && that.new_list.length <= 4) {
              that.new_list.push(val);
            }
          });
        });
    },

    //下单流程
    async get_process() {
      let that = this;
      await that.$axios
        .get(
          'site/get_site_newList?cid=6&page=1&limit=15'
        )
        .then((res) => {
          that.list = res.data.data.all;
        });
    },
  },
};
</script>

<style scoped>
.title-color {
  color: #0bb4e7;
}
.grey-bg {
  background: #f7f7f7;
}

.process-bg {
  background-image: url("../assets/image/process-bg.png");
}
.background-max {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.index-pro:first-child ._pos-abs-right {
  left: 50%;
}
.index-pro:last-child ._pos-abs-right {
  right: 50%;
}

.evaluate-box {
  display: grid;
  grid-template-columns: 22.39583vw 22.39583vw 22.39583vw;
  column-gap: 2.34375vw;
  row-gap: 2.34375vw;
}

.evaluate-img {
  display: grid;
  grid-template-columns: 4.94792vw 4.94792vw 4.94792vw;
  column-gap: 0.78125vw;
}
.news-hover:hover div {
  color: #0bb4e7;
}

._btn-hover:hover {
  opacity: 0.4;
}
._btn-p-hover:hover {
  background: #0bb4e7;
  color: #fff;
}
/* 淡入动画 */

.animaLeft{
animation: animaLeft 1s linear;
/* 停留在动画的最后一帧 */
animation-fill-mode: forwards;
}

.animaRight{
animation: animaRight 1s linear;
/* 停留在动画的最后一帧 */
animation-fill-mode: forwards;
}

</style>
