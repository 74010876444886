<template>
	<div>
		<!-- <HeadVue></HeadVue> -->
		<HeadVueTwo></HeadVueTwo>
		<router-view></router-view>
		<FooterVueTwo v-if="showFooter"></FooterVueTwo>
		<!-- <FooterVue></FooterVue> -->
	</div>
</template>

<script>
	import HeadVue from './components/Head.vue';
	import HeadVueTwo from './components/HeadTwo.vue';
	import FooterVue from './components/Footer.vue';
	import FooterVueTwo from './components/FooterTwo.vue';
	export default {
		components: {
			HeadVue,
			HeadVueTwo,
			FooterVue,
			FooterVueTwo,
		},
		data() {
			return {
				link: '',
				showFooter:true,
			}
		},
		watch: {
			$route: {
				handler(newval, oldval) {
					let hiddenFooter = [
						'/login',
						'/find_pwd',
						'/register',
						'/about_us',
						'/message',
						'/addressInfo',
						'/addressList',
						'/mb_list',
						'/mb_detail',
						'/mobile_addressList',
						'/ChangeAddress'
					];
					this.link = newval.path;
					this.showFooter = true;
					hiddenFooter.forEach((item, index) => {
						if (this.link == item) {
							this.showFooter = false;
						}
					});
				}
			},
		},
	};
</script>
<style>
	@import 'assets/iconfont/iconfont2.css';

	input{border:unset;outline-style: none;}
	textarea{border:unset;outline-style: none;}
	select{outline-style: none;background-color: unset;}
	a,button,ul,li {
		cursor: pointer;
	}
	button,a {
		user-select: none;
	 	-webkit-user-select: none;
	 	-moz-user-select:none;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	/* 淡入动画 */
	/* @keyframes anima {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

	/**
从左淡入
 */
	@keyframes animaLeft {
		from {
			opacity: 0;
			transform: translateX(-100%);
		}

		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

	/**
从右淡入
 */
	@keyframes animaRight {
		from {
			opacity: 0;
			transform: translateX(100%);
		}

		to {
			opacity: 1;
			transform: translateX(0);
		}
	}
</style>